import RfpGridConfig from "./rfp_grid_config";

export default class FullGridConfig extends RfpGridConfig {
  configureColumnsWidth(width, col) {
    if (col == 2) {
      return 500;
    }
    if (width > 200) {
      return 200;
    }
  }

  unitTypes() {
    // filled by handsontableController.getMediaTypesAndSubtypes()
    return [];
  }

  screenSubtypes() {
    // filled by handsontableController.getMediaTypesAndSubtypes()
    return [];
  }

  validateSpotLength(value, callback) {
    const rowIndex = this.row;
    const colIndex = this.instance.propToCol("screen_type");
    const screenType = this.instance.getDataAtCell(rowIndex, colIndex);

    if (screenType !== "digital") {
      return callback(true);
    } else if (screenType === "digital" && value && value > 0) {
      return callback(true);
    } else {
      return callback(false);
    }
  }

  validateTotalSpots(value, callback) {
    const rowIndex = this.row;
    const colIndex = this.instance.propToCol("screen_type");
    const screenType = this.instance.getDataAtCell(rowIndex, colIndex);

    if (screenType !== "digital") {
      return callback(true);
    } else if (screenType === "digital" && value && value > 0) {
      return callback(true);
    } else {
      return callback(false);
    }
  }

  gridColumns() {
    let columns = [
      {
        key: "supplier_face_id",
        title: "Face ID",
        read_only: this.readonly(),
        type: "text",
        allowEmpty: false,
        validator: this.checkAllowEmpty,
        errorMessage: this.errorMessages.required,
      },
      {
        key: "name",
        title: "Street Intersection",
        read_only: this.readonly(),
        type: "text",
        allowEmpty: false,
        validator: this.checkAllowEmpty,
        errorMessage: this.errorMessages.required,
      },
      { key: "description", title: "Description of Unit", read_only: this.readonly(), type: "text", allowEmpty: true },
      {
        key: "rationale",
        title: "Rationale / Unit Notes",
        read_only: this.readonly(),
        type: "text",
        allowEmpty: !this.config.rationale_mandatory,
        validator: this.checkMinLength,
        errorMessage: this.errorMessages.required_min_length,
      },
      {
        key: "start_date",
        title: "Start Date",
        read_only: this.readonly(),
        type: "text",
        allowEmpty: false,
        validator: this.checkAllowEmpty,
        errorMessage: this.errorMessages.required,
      },
      {
        key: "end_date",
        title: "End Date",
        read_only: this.readonly(),
        type: "text",
        allowEmpty: false,
        validator: this.checkAllowEmpty,
        errorMessage: this.errorMessages.required,
      },
      {
        key: "next_booked_date",
        title: "Next Booked Date",
        read_only: this.readonly(),
        type: "text",
        allowEmpty: this.allowEmptyNextBookedDate(),
        validator: this.checkAllowEmpty,
        errorMessage: this.errorMessages.required,
      },
      {
        key: "size",
        title: "Size",
        read_only: this.readonly(),
        type: "text",
        allowEmpty: false,
        validator: this.checkAllowEmpty,
        errorMessage: this.errorMessages.required,
      },
      {
        key: "screen_type",
        title: "Static or Digital",
        read_only: this.readonly(),
        type: "dropdown",
        source: ["static", "digital"],
        allowEmpty: false,
        validator: this.checkAllowEmpty,
        errorMessage: this.errorMessages.screen_type,
      },
      {
        key: "screen_subtype",
        title: "Screen Subtype",
        read_only: this.readonly(),
        type: "dropdown",
        source: this.screenSubtypes(),
        allowEmpty: true,
        errorMessage: this.errorMessages.screen_subtype,
      },
      {
        key: "unit_type",
        title: "Unit Type",
        read_only: this.readonly(),
        type: "dropdown",
        source: this.unitTypes(),
        allowEmpty: false,
        validator: this.unitTypeValidator(this.config),
        errorMessage: this.errorMessages.unit_type,
      },
      {
        key: "unit_subtype",
        title: "Unit SubType",
        read_only: this.readonly(),
        type: "dropdown",
        source: [],
        allowEmpty: false,
        errorMessage: this.errorMessages.unit_subtype,
      },
      {
        key: "lat",
        title: "Latitude",
        read_only: this.readonly(),
        type: "numeric",
        allowEmpty: false,
        validator: this.checkAllowEmpty,
        errorMessage: this.errorMessages.numeric_required,
      },
      {
        key: "lon",
        title: "Longitude",
        read_only: this.readonly(),
        type: "numeric",
        allowEmpty: false,
        validator: this.checkAllowEmpty,
        errorMessage: this.errorMessages.numeric_required,
      },
      {
        key: "impressions",
        title: "4 week Impressions (0+)",
        read_only: this.readonly(),
        type: "numeric",
        allowEmpty: false,
        validator: this.validateImpression,
        errorMessage: this.errorMessages.impressions,
      },
    ];

    if (this.showPriceColumn()) {
      if (this.isShortFlight()) {
        columns.push(this.priceForDurationColumn())
        columns.push(this.calculatedPriceColumn())
      } else {
        columns.push(this.priceColumn())
        columns.push(this.calculatedPriceForDurationColumn())
      }
    }

    columns.push(this.calculatedCpmColumn());

    if (!this.isShortFlight()) {
      columns = columns.concat([
        { key: 'rate_card_price', title: '4 Week Rate Card Price', read_only: this.readonly(), type: 'numeric', allowEmpty: false, errorMessage: this.errorMessages.numeric },
      ]);
    }

    columns = columns.concat([
      {
        key: "installation_cost",
        title: "Installation Cost",
        read_only: this.readOnlyInstallCost(),
        type: "numeric",
        allowEmpty: false,
        errorMessage: this.errorMessages.numeric_required,
      },
    ]);

    if (!this.hideProductionCost()) {
      columns.push({
        key: "production_cost",
        title: "Production cost",
        read_only: this.readonly(),
        type: "numeric",
        allowEmpty: false,
        errorMessage: this.errorMessages.numeric_required,
      });
    }

    columns = columns.concat([
      {
        key: "direction",
        title: "Facing",
        read_only: this.readonly(),
        type: "dropdown",
        source: ["N", "S", "E", "W", "NE", "NW", "SE", "SW", "Multiple"],
        allowEmpty: true,
        validator: this.checkAllowEmpty,
      },
      {
        key: "orientation",
        title: "Read",
        read_only: this.readonly(),
        type: "dropdown",
        source: ["RHR", "LHR", "CR"],
        allowEmpty: this.allowEmptyOrientation(),
        validator: this.checkAllowEmpty,
        errorMessage: this.errorMessages.required,
      },
      {
        key: "illuminated",
        title: "Illuminated",
        read_only: this.readonly(),
        type: "dropdown",
        source: ["16 HRS", "17 HRS", "18 HRS", "19 HRS", "20 HRS", "21 HRS", "22 HRS", "23 HRS", "24 HRS", "NO"],
        allowEmpty: false,
        validator: this.checkAllowEmpty,
        errorMessage: this.errorMessages.required,
      },
      {
        key: "spot_length",
        title: "Spot Length (in Seconds)",
        read_only: this.readonly(),
        type: "text",
        allowEmpty: true,
        validator: this.validateSpotLength,
        errorMessage: this.errorMessages.digital_required,
      },
      {
        key: "total_spots",
        title: "Number of Slots (if digital)",
        read_only: this.readonly(),
        type: "numeric",
        allowEmpty: true,
        validator: this.validateTotalSpots,
        errorMessage: this.errorMessages.digital_required,
      },
      { key: "tab_panel_id", title: "Geopath Panel ID", read_only: this.readonly(), type: "text", allowEmpty: true },
      {
        key: "remote_image_url",
        title: "Link to Image",
        read_only: this.readonly(),
        type: "text",
        allowEmpty: true,
        validator: this.checkUrl,
        errorMessage: this.errorMessages.url,
      },
      {
        key: "allow_ad_extensions",
        title: "Can accept Topper Out/Extension?",
        read_only: this.readonly(),
        type: "dropdown",
        source: ["Y", "N"],
        validator: this.checkBoolean,
        errorMessage: this.errorMessages.boolean,
        allowEmpty: true,
        allowInvalid: true,
      },
      {
        key: "forced_production",
        title: "Forced Production?",
        read_only: this.readonly(),
        type: "dropdown",
        source: ["Y", "N"],
        validator: this.checkBoolean,
        errorMessage: this.errorMessages.boolean_required,
        allowEmpty: this.allowForcedProductionEmpty(),
      },
      {
        key: "cancellation_policy",
        title: "Cancellation Policy",
        read_only: this.readonly(),
        type: "text",
        validator: this.checkAllowEmpty,
        errorMessage: this.errorMessages.required,
        allowEmpty: this.allowCancellationPolicyEmpty(),
      },
      {
        key: "design_asset_due_date",
        title: "Artwork Deadline",
        read_only: this.readonly(),
        type: "text",
        validator: this.checkAllowEmpty,
        errorMessage: this.errorMessages.required,
        allowEmpty: this.allowDesignAssetDueDateEmpty(),
      },
      {
        key: "artwork_pre_approval",
        title: "Will Artwork need Pre-Approval",
        read_only: this.readonly(),
        type: "dropdown",
        source: ["Y", "N"],
        validator: this.checkAllowEmpty,
        errorMessage: this.errorMessages.boolean_required,
        allowEmpty: this.allowArtworkPreApprovalEmpty(),
      },
      this.replacementForColumn(),
      this.previousAdvertisers(),
    ]);

    if (this.allowBonusValue()) {
      columns = columns.concat(this.bonusValueColumns());
    }

    window.gridColumns = columns.map((c) => c.key);

    return columns;
  }

  showPriceColumn() {
    return this.config.hide_supplier_price === false;
  }

  priceColumn() {
    return {
      key: "price",
      title: this.priceColumnName(),
      read_only: this.readonly(),
      type: "numeric",
      allowEmpty: false,
      errorMessage: this.errorMessages.numeric_required,
    };
  }

  calculatedPriceColumn() {
    return { key: 'calculated_price', title: 'Calculated Price', read_only: true, type: 'numeric', renderer: 'custom.calculated-four-week-price', allowEmpty: true }
  }

  calculatedPriceForDurationColumn() {
    return { key: 'calculated_price_for_duration', title: 'Calculated Price for duration', read_only: true, type: 'numeric', renderer: 'custom.calculated-price-for-duration', allowEmpty: true }
  }

  calculatedCpmColumn() {
    return { key: 'cpm', title: 'Calculated CPM', read_only: true, renderer: 'custom.calculated-cpm', allowEmpty: true };
  }

  sampleRow() {
    return {
      supplier_face_id: "EXAMPLE-001",
      name: "5th Ave & 41th St",
      description: "Catches traffic on one of the busiest intersections in the city",
      rationale: "Example: within target zip (X) and is seen by pedestrians/commuters going north.",
      start_date: "1/1/2019",
      end_date: "12/31/2019",
      next_booked_date: "2/2/2020",
      size: "19' 6\" x 48' 0",
      lat: 30.7607,
      lon: -53.9633,
      impressions: 559996,
      price: 2500,
      rate_card_price: 3000,
      installation_cost: 400,
      production_cost: 200,
      screen_type: "digital",
      unit_type: "billboard",
      unit_subtype: "Kiosks",
      direction: "N",
      orientation: "Left",
      illuminated: "Y",
      spot_length: 10,
      total_spots: 6,
      tab_panel_id: "481552",
      remote_image_url: "http://adquick.com/image.jpg",
      allow_ad_extensions: "N",
      forced_production: "N",
      cancellation_policy: "Sample cancellation policy",
      design_asset_due_date: "2/25/2021",
      artwork_pre_approval: "N",
    };
  }
}
